/**
 * Site Debugging.
 * @type {string}
 */
window.siteEnv = 'prod';

/**
 * Site JS configuration
 * @type {Object}
 */
window.siteConfig = {
  lang: {
    available: ['es', 'gl-ES', 'en'],
    default: 'es'
  },
  api: {
    url: 'https://directus.alialabs.com/',
    project: 'enalia',
    token: '53VaN1SI7IL5pkhXkqRkH4Zo',
  },
  binder: {
    showdown: {
      noHeaderId: true,
      headerLevelStart: 3
    }
  },
  ui: {
    animations: {
      active: true,
      params: {}
    },
    layout: {
      mobileBreakpoint: 'SM',
      breakpoints: {
        retina: {
          label: 'XXS',
          min: 0,
          max: 320
        },
        mobile: {
          label: 'XS',
          min: 321,
          max: 575
        },
        tablet: {
          label: 'SM',
          min: 576,
          max: 767
        },
        medium: {
          label: 'MD',
          min: 768,
          max: 991
        },
        desktop: {
          label: 'LG',
          min: 992,
          max: 1199
        },
        wide: {
          label: 'XL',
          min: 1200,
          max: 9999
        }
      }
    }
  },
  layoutDisplay: {
    active: (window.siteEnv === 'dev'),
    helpers: {
      site_info: {
        active: true,
        label: 'Enalia-Web',
        acronym: 'ENALIA'
      },
      breakpoints: {
        active: true
      },
      measures: {
        active: true
      }
    }
  }
};
