'use strict';

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

/**
 * Model Class for page's data.
 *
 * @lint global PageModel
 */
window.PageModel = function PageModel(rawData) {
  var _this = this;

  _classCallCheck(this, PageModel);

  this.texts = {};
  this.sections = {};

  rawData.sections.forEach(function (sectionRaw) {
    _this.sections[sectionRaw.slug] = {
      codigo: sectionRaw.slug,
      titulo: sectionRaw.titulo,
      descripcion: sectionRaw.descripcion
    };
  });

  rawData.texts.forEach(function (textRaw) {
    if (typeof _this.texts[textRaw.seccion.slug] === 'undefined') _this.texts[textRaw.seccion.slug] = [];
    _this.texts[textRaw.seccion.slug][textRaw.slug] = textRaw.contenido;
  });
};