/**
 * Class for app's data management
 * @requires Axios
 *
 * @lint global Data
 */
window.Data = class Data {

  /**
   * Class constructor
   * @param {object} apiConfig data API configuration parameters.
   */
  constructor(apiConfig) {

    /**
     * Directus Wrapper API instance
     * @type {Axios | null}
     */
    this.api = null;

    if (apiConfig) {
      this.initRemoteApi(apiConfig);
    }
  }

  /**
   * Initialize Remote Data provider.
   *
   * @param {object} apiConfig Api config info
   */
  initRemoteApi(apiConfig) {

    this.api = new DirectusWrapper(apiConfig);

  }

  executeCall(collectionMethod) {

    return collectionMethod();

  }


};
