'use strict';

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _get = function get(object, property, receiver) { if (object === null) object = Function.prototype; var desc = Object.getOwnPropertyDescriptor(object, property); if (desc === undefined) { var parent = Object.getPrototypeOf(object); if (parent === null) { return undefined; } else { return get(parent, property, receiver); } } else if ("value" in desc) { return desc.value; } else { var getter = desc.get; if (getter === undefined) { return undefined; } return getter.call(receiver); } };

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

/**
 * Directus 'special' field manager.
 *
 * @author Brais Saco Estévez
 * @abstract
 * @extends Base
 * @lint global BaseField
 */
window.BaseField = function (_Base) {
  _inherits(BaseField, _Base);

  function BaseField() {
    _classCallCheck(this, BaseField);

    return _possibleConstructorReturn(this, (BaseField.__proto__ || Object.getPrototypeOf(BaseField)).apply(this, arguments));
  }

  _createClass(BaseField, [{
    key: '_initAttributes',


    // =========================================================================
    // INITIALIZATION
    // =========================================================================

    value: function _initAttributes() {
      var _get2;

      for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
        args[_key] = arguments[_key];
      }

      (_get2 = _get(BaseField.prototype.__proto__ || Object.getPrototypeOf(BaseField.prototype), '_initAttributes', this)).call.apply(_get2, [this].concat(args));

      /**
       * Fields parameter to retrieve from Directus' API.
       * @type {string[]}
       */
      this.queryFields = ['*'];

      /**
       * Fields to manage for every field item. Obvious other fields.
       * @type {Object}
       */
      this.fieldMap = {};
    }

    // =========================================================================
    // PUBLIC METHODS
    // =========================================================================

    /**
     * Return fields parameter value for API query.
     * @return {string[]} List of fields for API query
     */

  }, {
    key: 'getQueryFields',
    value: function getQueryFields() {
      return this.queryFields;
    }

    /**
     * Map Raw fields from the API response into new collection or unique object with parsed values.
     * @param {Array} rawFieldData Collection of items received as response from API.
     * @param {string} linkField Uses when a previous relation object field needs to be prefixed to
     * a field reference.
     * @return {[]|{}} Collection with parsed values or an unique element of the collection.
     */

  }, {
    key: 'mapItemsField',
    value: function mapItemsField(rawFieldData, linkField) {
      var map = this.fieldMap;
      if (_.isEmpty(map)) return rawFieldData;
      var mappedCollection = [];
      for (var i = 0; i < rawFieldData.length; i++) {
        var rawItem = rawFieldData[i];
        var mappedItem = rawItem ? window.BaseField.mapItem(rawItem, map, linkField) : null;
        mappedCollection.push(mappedItem);
      }
      return mappedCollection;
    }

    /**
     * Maps single item fields using field map correspondence.
     * @param {Object} rawItem single item of a collection
     * @param {Object} fieldMap field map correspondence
     * @param {string} prefixFields Preffix to prepend to all fields of the field map
     * @return {Object} Mapped item values.
     */

  }], [{
    key: 'mapItem',
    value: function mapItem() {
      var rawItem = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      var fieldMap = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
      var prefixFields = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;

      var mappedItem = {};
      _.each(fieldMap, function (mappedField, rawField) {
        var itemValue = '';

        // Prefix link field if its neccesary.
        if (prefixFields) {
          rawField = prefixFields + '.' + rawField;
        }

        var valueDepth = rawField.split('.');
        if (valueDepth.length === 1) {
          itemValue = rawItem[rawField];
        } else {
          if (_.isArray(rawItem[valueDepth[0]])) {
            itemValue = rawItem[valueDepth[0]][0];
          } else {
            itemValue = rawItem[valueDepth[0]];
          }
          for (var d = 1; d < valueDepth.length; d++) {
            var token = valueDepth[d];
            if (d < valueDepth.length - 1) {
              if (_.isArray(itemValue[token])) {
                itemValue = itemValue[token][0];
              } else {
                itemValue = itemValue[token];
              }
            } else {
              itemValue = itemValue[token];
            }
          }
        }
        mappedItem[mappedField] = itemValue;
      });
      return mappedItem;
    }
  }]);

  return BaseField;
}(Base);