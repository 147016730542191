'use strict';

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

/**
 * Static util's class
 * @lint global Utils
 */
window.Utils = function () {
  function Utils() {
    _classCallCheck(this, Utils);
  }

  _createClass(Utils, null, [{
    key: 'uniqueId',


    /**
     * Generate a time-based unique id.
     * @param {String} prefix String to prefix at the beginning of the id.
     * @return {String} Unique id
     */
    value: function uniqueId(prefix) {
      var prex = prefix || '';
      var uniqid = Date.now();
      return prex + uniqid;
    }

    /**
     * Get URL parameters from an URL.
     * @param {String} sParam Parameter to search. If it's empty returns all parameters.
     * @return {*} URL's GET parameters
     */

  }, {
    key: 'getUrlParameter',
    value: function getUrlParameter(sParam) {
      var sPageURL = decodeURIComponent(window.location.search.substring(1));
      var sURLVariables = sPageURL.split('&');
      var sParameterName = void 0;
      var i = void 0;
      var returnVal = void 0;

      for (i = 0; i < sURLVariables.length; i++) {
        sParameterName = sURLVariables[i].split('=');

        if (typeof sParam === 'undefined') {
          returnVal = returnVal || {};
          returnVal[sParameterName[0]] = sParameterName[1];
        } else if (sParameterName[0] === sParam) {
          returnVal = typeof sParameterName[1] === 'undefined' ? true : sParameterName[1];
        }
      }
      return returnVal;
    }

    /**
     * Replace all occurrences of a substring in a string for another string.
     * @param {String} string String where to find
     * @param {String} search Substring to search
     * @param {String} replacement String to replace for search occurrences
     * @return {String} Resulting string
     */

  }, {
    key: 'replaceAll',
    value: function replaceAll(string, search, replacement) {
      return string.replace(new RegExp(search, 'g'), replacement);
    }

    /**
     * Copy custom text or an jquery element's text into clipboard.
     * @requires jQuery
     * @param {jQuery|string} element Jquery element or text to copy
     */

  }, {
    key: 'copyToClipboard',
    value: function copyToClipboard(element) {
      var copyText = element;
      if (element instanceof jQuery) {
        copyText = element.text();
      }
      var $temp = $('<input>');
      $('body').append($temp);
      $temp.val(copyText).select();
      document.execCommand('copy');
      $temp.remove();
    }

    /**
     * Get all CSS rules of the page.
     * Needs HTTP protocol!
     * @param {String} filter CSS rule filter (Beginning of the rule)
     * @return {Array} CSS rules
     */

  }, {
    key: 'readAllCssRules',
    value: function readAllCssRules(filter) {
      var allRules = [];
      var sSheetList = document.styleSheets;
      for (var sSheet = 0; sSheet < sSheetList.length; sSheet++) {
        var ruleList = document.styleSheets[sSheet].cssRules;
        for (var rule = 0; rule < ruleList.length; rule++) {
          var selectorText = ruleList[rule].selectorText;
          if (selectorText) {
            if (!filter || !!filter && selectorText.search(filter) === 0) {
              allRules.push(ruleList[rule].selectorText);
            }
          }
        }
      }
      return allRules;
    }

    /**
     * Multi line Ellipsis text.
     * @requires jQuery
     * @param {jQuery|string} container Must have fixed height and overflow:hidden
     * @param {jQuery|string} towrap Element to wrap and apply ellipsis based on container's height.
     * @param {integer} customHeight Custom height that overrides the container's height.
     * @param {boolean} adjustHeight If $container is higher than $item, adjust to same height.
     */

  }, {
    key: 'multiLineEllipsis',
    value: function multiLineEllipsis(container, towrap, customHeight, adjustHeight) {
      var $container = $(container);
      var $text = $container.find(towrap);
      var containerHeight = 0;
      var adjust = adjustHeight || false;

      if (typeof customHeight !== 'undefined' && customHeight !== false) {
        containerHeight = customHeight;
      } else {
        containerHeight = $container.height();
      }

      if ($container.css('overflow') === 'hidden') {
        if ($text.outerHeight() > containerHeight) {
          var maxloop = 5000;
          while ($text.outerHeight() > containerHeight) {
            // Avoid infinite loop.
            if (!maxloop) break;
            maxloop -= 1;

            $text.text(function (index, text) {
              return text.replace(/\W*\s(\S)*$/, '...');
            });
          }
        } else if (adjust) {
          // @TODO: Check malfunction (If this is active, triggers overloadings).
          // $container.css("height", "auto");
        }
      }
    }

    /**
     * Detects the browser that is rendering the web.
     * @see https://stackoverflow.com/questions/9847580/how-to-detect-safari-chrome-ie-firefox-and-opera-browser
     * @return {string} firefox, chrome, safari, opera, ie, other
     */

  }, {
    key: 'checkBrowser',
    value: function checkBrowser() {
      var isOpera = !!window.opera || navigator.userAgent.indexOf(' OPR/') >= 0;
      var isFirefox = typeof InstallTrigger !== 'undefined'; // Firefox 1.0+
      var isSafari = Object.prototype.toString.call(window.HTMLElement).indexOf('Constructor') > 0;
      var isChrome = !!window.chrome && !isOpera; // Chrome 1+
      var isIE = /* @cc_on!@ */false || !!document.documentMode;
      var isEdge = !isIE && !!window.StyleMedia;

      var browser = 'other';
      if (isOpera) {
        browser = 'opera';
      } else if (isFirefox) {
        browser = 'firefox';
      } else if (isSafari) {
        browser = 'safari';
      } else if (isChrome) {
        browser = 'chrome';
      } else if (isIE || isEdge) {
        browser = 'ie';
      }

      return browser;
    }

    /**
     * Calculates the value in pixels of an "em".
     * @requires jQuery
     * @param {jQuery|string} element Element with the em. Default is body.
     * @param {integer} em Number of "em". Default is 1.
     * @return {integer} Size of "em" in pixels.
     */

  }, {
    key: 'emToPx',
    value: function emToPx(element, em) {
      if (typeof element === 'undefined') {
        element = 'body';
      }

      if (typeof em === 'undefined') {
        em = 1;
      }

      var div = $('<div style="width: ' + em + 'em;"></div>').appendTo(element);
      var px = div.width();
      div.remove();

      return px;
    }

    /**
     * Orders an array of objects by object property.
     * @param {Array} array Array of objects to sort
     * @param {String} key Key common to all objects in array
     * @return {Array} Sorted array
     */

  }, {
    key: 'sortByKey',
    value: function sortByKey(array, key) {
      return array.sort(function (a, b) {
        var x = a[key];
        var y = b[key];
        return x < y ? -1 : x > y ? 1 : 0; // eslint-disable-line no-nested-ternary
      });
    }

    /**
     * Function that waits for an element to exist and then executes a callback.
     *
     * Usefull when the onload event can't be used reliably.
     * If you want to use "this" within the callback function you have to pass it by reference as
     * an argument.
     *
     * @requires jQuery
     *
     * @param {String} mode
     *   "element" -> Waits for that element to exist.
     *   "container" -> Waits for the element to have children.
     * @param {String} selector
     *   jQuery-formatted selector string.
     * @param {Function} callback
     *   Function to execute when the element exists. You should pass it with trailing "()".
     *   Accepts arguments.
     * @param {Object} context
     *   A context you need available in the function. Not in use at the moment.
     * @param {Integer} timeout
     *   The timeout between checks in milliseconds.
     * @param {Integer} times
     *   Internal param. The times the function has been called in a single iteration.
     *   Stops at 20 calls.
     */

  }, {
    key: 'waitForElement',
    value: function waitForElement(mode, selector, callback) {
      var context = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : null;
      var timeout = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : 200;
      var times = arguments.length > 5 && arguments[5] !== undefined ? arguments[5] : 0;

      var self = void 0;
      var jobject = void 0;

      if (context != null) self = context;else self = this;

      if (mode === 'element') {
        jobject = $(selector);
      } else if (mode === 'container') {
        jobject = $(selector).children();
      } else {
        jobject = null;
      }

      if (jobject.length) {
        callback();
      } else if (times < 20) {
        setTimeout(function () {
          self._waitForElement(mode, selector, callback, context, timeout, times + 1);
        }, timeout);
      }
    }

    /**
     * Camel case string.
     * @param {string} str String to camel case
     * @return {string} Camelcased string
     */

  }, {
    key: 'camelize',
    value: function camelize(str) {
      return str.toLowerCase().replace(/[^a-zA-Z0-9]+(.)/g, function (m, chr) {
        return chr.toUpperCase();
      });
    }

    /**
     * Data attribute to string camel-cased (data-attribute)
     * @param {string} dataAttr data attribute with []
     * @return {string} Camelcased string
     */

  }, {
    key: 'dataAttrToString',
    value: function dataAttrToString(dataAttr) {
      var parsed = dataAttr.toLowerCase().replace(/\[|\]|data-/g, '');
      return Utils.camelize(parsed);
    }

    /**
     * Append suffix to data-attribute
     * @param {string} dataAttr data attribute with []
     * @param {string} append Appended text to attribute
     * @param {bool} camelCased Convert attribute string to camel case format
     * @return {string} appended data-attribute
     */

  }, {
    key: 'dataAttrAppend',
    value: function dataAttrAppend(dataAttr) {
      var append = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : '';
      var camelCased = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;

      var newAttr = dataAttr.replace(']', '-' + append + ']');
      if (camelCased) {
        newAttr = Utils.dataAttrToString(newAttr);
      }
      return newAttr;
    }
  }]);

  return Utils;
}();