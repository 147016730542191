/**
 * Class for Main menu block management
 * @extends BaseBlockController
 *
 * @lint global MainMenuBlockController
 */
window.MainMenuBlockController = class MainMenuBlockController extends BaseBlockController {

  /**
   * Class constructor.
   */
  constructor() {

    super('main-menu');

  }

  /**
   * @override
   */
  bindData(data) {

    let formattedData = { sections: data };

    super.bindData(formattedData);

  }

  /**
   * Initialize DOM behaviour
   * @override
   */
  initBehaviour() {

    this.initLinks();

  }

  /**
   * Initialize menu links behaviour.
   */
  initLinks() {
    this.dom.find('.nav-item a')
      .off('click.main-menu.block')
      .on('click.main-menu.block', () => {
        if (window.layoutManager.isMobile()) {
          $('.block-main-nav .navbar-toggler')
            .trigger('click');
        }
      });
  }

};
