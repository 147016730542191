/**
 * Model Class for language's data.
 *
 * @lint global LanguageModel
 */
window.LanguageModel = class LanguageModel {

  constructor(rawData) {
    this.codigo = rawData.codigo;
    this.abreviatura = rawData.abreviatura;
    this.nombre = rawData.nombre;
  }

};
