/**
 * Class for home page management
 * @extends BasePageController
 *
 * @lint global HomePageController
 */
window.HomePageController = class HomePageController extends BasePageController {

  /**
   * Class constructor.
   */
  constructor() {

    super('home');

  }

};
