/**
 * Class for Footer block management
 * @extends BaseBlockController
 *
 * @lint global FooterBlockController
 */
window.FooterBlockController = class FooterBlockController extends BaseBlockController {

  /**
   * Class constructor.
   */
  constructor() {

    super('footer');

  }

  /**
   * @override
   */
  bindData(data) {

    let formattedData = data;

    formattedData['actual-year'] = new Date().getFullYear().toString();

    super.bindData(formattedData);

  }

};
