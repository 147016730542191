'use strict';

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _get = function get(object, property, receiver) { if (object === null) object = Function.prototype; var desc = Object.getOwnPropertyDescriptor(object, property); if (desc === undefined) { var parent = Object.getPrototypeOf(object); if (parent === null) { return undefined; } else { return get(parent, property, receiver); } } else if ("value" in desc) { return desc.value; } else { var getter = desc.get; if (getter === undefined) { return undefined; } return getter.call(receiver); } };

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

/**
 * Directus Collection Base Model
 *
 * @author Brais Saco Estévez
 * @abstract
 * @extends Base
 * @lint global BaseCollection
 */
window.BaseCollection = function (_Base) {
  _inherits(BaseCollection, _Base);

  function BaseCollection() {
    _classCallCheck(this, BaseCollection);

    return _possibleConstructorReturn(this, (BaseCollection.__proto__ || Object.getPrototypeOf(BaseCollection)).apply(this, arguments));
  }

  _createClass(BaseCollection, [{
    key: '_initAttributes',


    // =========================================================================
    // INITIALIZATION
    // =========================================================================

    /**
     * @override
     * @see Base
     */
    value: function _initAttributes() {
      var _get2;

      for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
        args[_key] = arguments[_key];
      }

      (_get2 = _get(BaseCollection.prototype.__proto__ || Object.getPrototypeOf(BaseCollection.prototype), '_initAttributes', this)).call.apply(_get2, [this].concat(args));

      /**
       * Directus' Collection Name.
       * @type {string}
       */
      this.collectionName = 'base';

      /**
       * Fields parameter to retrieve from Directus' API.
       * @type {string[]}
       */
      this.queryFields = ['*.*'];

      /**
       * Model for every item of the collection.
       * @type {Class}
       */
      this.MODEL = BaseItem;

      /**
       * Model for every item of the collection in views.
       * @type {Class}
       */
      this.VIEWMODEL = BaseView;
    }

    /**
     * Initialize this instance.
     */

  }, {
    key: '_initInstance',
    value: function _initInstance() {
      var _get3;

      for (var _len2 = arguments.length, args = Array(_len2), _key2 = 0; _key2 < _len2; _key2++) {
        args[_key2] = arguments[_key2];
      }

      (_get3 = _get(BaseCollection.prototype.__proto__ || Object.getPrototypeOf(BaseCollection.prototype), '_initInstance', this)).call.apply(_get3, [this].concat(args));

      // Create a single Collection model instance.
      this._MODEL_ = new this.MODEL();
    }

    // =========================================================================
    // PUBLIC METHODS
    // =========================================================================

    /**
     * Return fields parameter value for API query.
     * @return {string[]} List of fields for API query
     */

  }, {
    key: 'getQueryFields',
    value: function getQueryFields() {
      return this.queryFields.concat(this._MODEL_.getQueryTranslatableFields()).concat(this._MODEL_.getQueryRelationsFields());
    }

    /**
     * Retrieve Items from collection.
     * @see https://docs.directus.io/api/items.html#list-the-items
     * @param {Object} filter Filter conditions for items retrieving query.
     * @param {Object} extraOptions Other options for Directus query.
     * @param {boolean} checkStatus Check for published status filter on query
     * @param {boolean} checkLang Check for language items filter in query
     * @return {Promise} API Response
     */

  }, {
    key: 'getItems',
    value: function getItems() {
      var filter = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      var extraOptions = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
      var checkStatus = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : true;
      var checkLang = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : true;

      var params = {
        fields: this.getQueryFields()
      };
      // Filter by status
      if (checkStatus && this._MODEL_.hasStatus()) {
        filter[this._MODEL_.statusField] = this._MODEL_.statusPublished;
      }

      if (!_.isEmpty(filter)) {
        params.filter = filter;
      }

      // Filter by Global Language.
      if (checkLang && window.lang && this._MODEL_.hasTranslations()) {
        params.lang = window.lang;
      }

      // Another parameters for the query.
      if (!_.isEmpty(extraOptions)) {
        params = _.assignIn(params, extraOptions);
      }

      // return window.dataApi.getItems(this.collectionName, params);
      return this.fetchRequest({
        collection: this.collectionName,
        params: params
      });
    }

    /**
     * Retrieve an item of the collection by its id.
     * @param {number} id Id of the item.
     * @return {Promise} API Response
     */

  }, {
    key: 'getItemById',
    value: function getItemById(id) {
      return this.getItems({
        id: id
      });
    }

    /**
     * Map Raw fields from the API response into new collection or unique object with parsed values.
     * @see BaseItem.fieldMap
     * @param {Array} rawCollection Collection of items received as response from API.
     * @param {string} linkField Uses when a previous relation object field needs to be prefixed to
     * a field reference.
     * @param {boolean} raw Uses raw value or not (Creates a view model instance for every item)
     * @return {[]|{}} Collection with parsed values or an unique element of the collection.
     */

  }, {
    key: 'mapItemsField',
    value: function mapItemsField(rawCollection, linkField) {
      var _this2 = this;

      var raw = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;

      var map = this._MODEL_.getFieldMap(); // Retrieve fields MAP.
      var relations = this._MODEL_.getRelations(); // Retrieve collection's relations.
      var translations = this._MODEL_.translations; // Retrieve collection's relations.
      var mappedCollection = [];

      if (_.isEmpty(map) && _.isEmpty(relations) && !translations) {
        return this.formatMappedItem(rawCollection, raw);
      }

      var _loop = function _loop(i) {
        var rawItem = rawCollection[i];

        // -------------------------------------
        // Base Mapping.
        // -------------------------------------
        var mappedItem = window.BaseField.mapItem(rawItem, map, linkField);

        // -------------------------------------
        // Map Translations.
        // -------------------------------------
        if (translations) {
          var langFilter = '';
          if (window.lang) {
            langFilter = window.lang;
          }
          var translationsFields = _.head(translations.getTranslationsFieldData(rawItem, langFilter));
          _.each(translationsFields, function (value, name) {
            mappedItem[name] = value;
          });
        }

        // -------------------------------------
        // Map Relations.
        // -------------------------------------
        _.each(relations, function (relationObject, relationField) {
          mappedItem[relationField] = relationObject.getRelationFields(rawItem[relationField]);
        });

        // -------------------------------------
        // Add ITEM TO MAP
        // -------------------------------------
        mappedCollection.push(_this2.formatMappedItem(mappedItem, raw));
      };

      for (var i = 0; i < rawCollection.length; i++) {
        _loop(i);
      }

      return mappedCollection;
    }

    /**
     * Formats raw item in a view model or not.
     * @param {Object} mappedItem The item in raw format
     * @param {boolean} raw Uses raw value or not (Creates a view model instance)
     * @return {Object|VIEWMODEL} Raw object or VIEWMODEL instance
     */

  }, {
    key: 'formatMappedItem',
    value: function formatMappedItem(mappedItem, raw) {
      return raw ? mappedItem : new this.VIEWMODEL(mappedItem);
    }

    /**
     * Launch request to API or retrieve cached data.
     * @param {Object} requestInfo Collection and params
     * @return {Promise|*} Promise with returned data
     */

  }, {
    key: 'fetchRequest',
    value: function fetchRequest(requestInfo) {

      // USe cache system: requires objectHash and lscache libraries.
      if (window.objectHash && window.lscache) {
        var requestHash = objectHash.sha1(requestInfo);
        var data = lscache.get(requestHash);
        if (data) {
          this.log('[c=\'color:#FFCC00;\'][Directus][Get Items][CACHED]: ' + requestInfo.collection + '[c]\n', requestHash, requestInfo.params);
          return Promise.resolve(data);
        }
        this.log('[c=\'color:#33BB33;\'][Directus][Get Items][REQUEST]: ' + requestInfo.collection + '[c]\n', requestHash, requestInfo.params);
        return new Promise(function (resolve) {
          window.dataApi.getItems(requestInfo.collection, requestInfo.params).then(function (result) {
            if (window.cacheExpires) {
              lscache.set(requestHash, result, window.cacheExpires);
            }
            resolve(result);
          }).catch(function (error) {
            return console.error(error);
          });
        });
      }
      this.log('[c=\'color:#33BB33;\'][Directus][Get Items][REQUEST]: ' + requestInfo.collection + '[c]\n', requestInfo.params);
      return window.dataApi.getItems(requestInfo.collection, requestInfo.params);
    }
  }]);

  return BaseCollection;
}(Base);