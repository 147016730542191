'use strict';

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _get = function get(object, property, receiver) { if (object === null) object = Function.prototype; var desc = Object.getOwnPropertyDescriptor(object, property); if (desc === undefined) { var parent = Object.getPrototypeOf(object); if (parent === null) { return undefined; } else { return get(parent, property, receiver); } } else if ("value" in desc) { return desc.value; } else { var getter = desc.get; if (getter === undefined) { return undefined; } return getter.call(receiver); } };

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

/**
 * Directus Field Model: 'Translation'
 * For all translatable values of a collection
 *
 * @author Brais Saco Estévez
 * @extends Base
 * @lint global TranslationField
 */
window.TranslationField = function (_Base) {
  _inherits(TranslationField, _Base);

  function TranslationField() {
    _classCallCheck(this, TranslationField);

    return _possibleConstructorReturn(this, (TranslationField.__proto__ || Object.getPrototypeOf(TranslationField)).apply(this, arguments));
  }

  _createClass(TranslationField, [{
    key: '_initAttributes',


    // =========================================================================
    // INITIALIZATION
    // =========================================================================

    /**
     * @override
     * @see Base
     *
     * Receives an object of settings on constructor:
     * + translationsField {string} Translation field of a collection's item.
     * + translatableFields {Array} List of translatable values inside a translation field.
     * + translatableLangField {string} Field value that contains the language of the translation.
     */
    value: function _initAttributes() {
      var _get2;

      for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
        args[_key] = arguments[_key];
      }

      (_get2 = _get(TranslationField.prototype.__proto__ || Object.getPrototypeOf(TranslationField.prototype), '_initAttributes', this)).call.apply(_get2, [this].concat(args));

      /**
       * Field for manage collection's item translatable values.
       * @type {string}
       */
      this.translationsField = '';

      /**
       * List of translatable fields that was contained in 'translationField'
       * @type {Array}
       */
      this.translatableFields = [];

      /**
       * Field that contains language of the translation contained in 'translationField'
       * @type {string}
       */
      this.translatableLangField = '';

      // Retrieve arguments of the constructor.
      if (args[0]) {
        var constructorFields = args[0];
        this.translationsField = constructorFields.translationsField;
        this.translatableFields = constructorFields.translatableFields;
        this.translatableLangField = constructorFields.translatableLangField;
      }
    }

    // =========================================================================
    // PUBLIC METHODS
    // =========================================================================

    /**
     * Check if the item has translatable fields.
     * @return {boolean} Checked property value
     */

  }, {
    key: 'hasTranslations',
    value: function hasTranslations() {
      return this.translationsField && this.translatableLangField;
    }

    /**
     * Retrieve a list of translatable fields for API query.
     * @return {[]} List of translatable fields for API query
     */

  }, {
    key: 'getQueryTranslatableFields',
    value: function getQueryTranslatableFields() {
      var _self_ = this;
      var transFields = [];
      if (this.hasTranslations()) {
        transFields.push(_self_.translationsField + '.' + _self_.translatableLangField);
        for (var i = 0; i < _self_.translatableFields.length; i++) {
          var fieldName = _self_.translatableFields[i];
          transFields.push(_self_.translationsField + '.' + fieldName);
        }
      }
      return transFields;
    }

    /**
     * Retrieve complete translation lang field.
     * @return {string} Lang field in translated item.
     */

  }, {
    key: 'getQueryTranslationLangField',
    value: function getQueryTranslationLangField() {
      if (this.hasTranslations()) {
        return this.translationsField + '.' + this.translatableLangField;
      }
      return '';
    }
  }, {
    key: 'getTranslationsFieldData',
    value: function getTranslationsFieldData() {
      var itemData = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
      var langFilter = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'gl-ES';

      if (!this.hasTranslations() || !itemData[this.translationsField]) return {};
      var _self_ = this;
      var translationsField = itemData[this.translationsField];
      if (langFilter) {
        translationsField = _.filter(translationsField, function (translation) {
          return translation[_self_.translatableLangField] === langFilter;
        });
      }
      return translationsField;
    }
  }]);

  return TranslationField;
}(Base);