"use strict";

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _get = function get(object, property, receiver) { if (object === null) object = Function.prototype; var desc = Object.getOwnPropertyDescriptor(object, property); if (desc === undefined) { var parent = Object.getPrototypeOf(object); if (parent === null) { return undefined; } else { return get(parent, property, receiver); } } else if ("value" in desc) { return desc.value; } else { var getter = desc.get; if (getter === undefined) { return undefined; } return getter.call(receiver); } };

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

/**
 * Directus Item View Base Model
 *
 * @author Brais Saco Estévez
 * @abstract
 * @extends Base
 * @lint global BaseView
 */
window.BaseView = function (_Base) {
  _inherits(BaseView, _Base);

  function BaseView() {
    _classCallCheck(this, BaseView);

    return _possibleConstructorReturn(this, (BaseView.__proto__ || Object.getPrototypeOf(BaseView)).apply(this, arguments));
  }

  _createClass(BaseView, [{
    key: "_initAttributes",


    // =========================================================================
    // INITIALIZATION
    // =========================================================================

    /**
     * @override
     * @see Base
     */
    value: function _initAttributes(data) {
      var _this2 = this;

      _get(BaseView.prototype.__proto__ || Object.getPrototypeOf(BaseView.prototype), "_initAttributes", this).call(this, data);

      this.__data__ = data;

      /**
       * Stores view model data.
       */
      _.each(data, function (value, prop) {
        _this2[prop] = value;
      });
    }
  }, {
    key: "getRawData",
    value: function getRawData() {
      return this.__data__;
    }
  }]);

  return BaseView;
}(Base); // ;