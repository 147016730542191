'use strict';

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

/**
 * Class that manages animations for DOM elements.
 *
 * @author Brais Saco Estévez
 * @requires jQuery
 *
 * Parameters using data- attributes in HTML (Could be modified using configuration)
 *
 * - data-[appears|disappears]: animation direction (nomove|up|down|left|right).
 * - data-[appears|disappears]-delay: Delay time for animation begun.
 * - data-[appears|disappears]-offset: Offset to check visibility of the item in screen.
 * - data-[appears|disappears]-children: Apply animation to item's children.
 *
 * @lint global AnimationsController
 */
window.AnimationsController = function () {

  /**
   * Class constructor.
   * @param {object} config Configuration object
   */
  function AnimationsController() {
    var config = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};

    _classCallCheck(this, AnimationsController);

    this.config = config;

    this.appearsSelector = config.params.appearsSelector || '[data-appears]';

    this.disappearsSelector = config.params.disappearsSelector || '[data-disappears]';

    this.appearsClassPrefix = config.params.appearsClassPrefix || 'appears';

    this.disappearsClassPrefix = config.params.disappearsClassPrefix || 'disappears';

    this.paramDelaySuffix = config.params.paramDelaySuffix || 'delay';

    this.paramOffsetSuffix = config.params.paramOffsetSuffix || 'offset';

    this.paramChildrenSuffix = config.params.paramChildrenSuffix || 'children';

    this.paramDelayDefault = config.params.paramDelayDefault || 0;

    this.paramOffsetDefault = config.params.paramOffsetDefault || 100;

    this.paramDirectionDefault = config.params.paramDirectionDefault || 'nomove';

    this.init();
  }

  /**
   * Initialize class
   */


  _createClass(AnimationsController, [{
    key: 'init',
    value: function init() {

      if (!this.config.active) return;

      this.initAppearsAnimation();

      this.initDisappearsAnimation();
    }

    /**
     * Initialize appears in DOM animation.
     * [data-appears]: direction of the animation: up, down, left, right, nomove
     * [data-appears-delay]: ms of delay
     * [data-appears-offset]: viewport offset to define when an element is visible in
     * DOM before scrolling window
     */

  }, {
    key: 'initAppearsAnimation',
    value: function initAppearsAnimation() {

      var self = this;

      // Initial state for items to appear
      var initialClass = self.appearsClassPrefix + '-animation';
      var directionAttribute = Utils.dataAttrToString(self.appearsSelector);
      var delayAttribute = Utils.dataAttrAppend(self.appearsSelector, self.paramDelaySuffix, true);
      var offsetAttribute = Utils.dataAttrAppend(self.appearsSelector, self.paramOffsetSuffix, true);
      var childrenAttribute = Utils.dataAttrAppend(self.appearsSelector, self.paramChildrenSuffix, true);

      // Initialize to initial class.
      $(self.appearsSelector).addClass(initialClass);

      // Define animation handler for scrolling.
      var appearsHandler = function appearsHandler() {
        $(self.appearsSelector).not('.animation-processed').each(function (i, item) {
          var $item = $(item);
          var offset = $item.data(offsetAttribute) || self.paramOffsetDefault;
          if ($item.isInViewport(offset)) {
            var direction = $item.data(directionAttribute) || self.paramDirectionDefault;
            var delay = $item.data(delayAttribute) || self.paramDelayDefault;
            var applyToChildren = $item.data(childrenAttribute) || false;

            if (!applyToChildren) {
              // Apply animation to element.
              setTimeout(function () {
                $item.addClass(self.appearsClassPrefix + '-' + direction + ' animation-processed');
              }, delay);
            } else {
              // Apply animation to elements's children.
              var $children = $item.children();
              $item.removeClass(initialClass).addClass('animation-processed');
              $children.addClass(initialClass).each(function (c, child) {
                setTimeout(function () {
                  $(child).addClass(self.appearsClassPrefix + '-' + direction);
                }, delay * c);
              });
            }
          }
        });
      };

      // On window scroll.
      $(window).off('scroll.appears.animations').on('scroll.appears.animations', appearsHandler).off('resize.appears.animations').on('resize.appears.animations', appearsHandler);

      // On first load.
      appearsHandler();
    }

    /**
     * Initialize disappears in DOM animation.
     * [data-disappears]: direction of the animation: up, down, left, right, nomove
     * [data-disappears-delay]: ms of delay
     * [data-disappears-offset]: viewport offset to define when an element is visible in
     * DOM before scrolling window
     */

  }, {
    key: 'initDisappearsAnimation',
    value: function initDisappearsAnimation() {

      var self = this;

      // Initial state for items to appear
      var initialClass = self.disappearsClassPrefix + '-animation';
      var directionAttribute = Utils.dataAttrToString(self.disappearsSelector);
      var delayAttribute = Utils.dataAttrAppend(self.disappearsSelector, self.paramDelaySuffix, true);
      var offsetAttribute = Utils.dataAttrAppend(self.disappearsSelector, self.paramOffsetSuffix, true);
      var childrenAttribute = Utils.dataAttrAppend(self.appearsSelector, self.paramChildrenSuffix, true);

      // Initialize to initial class.
      $(self.disappearsSelector).addClass(initialClass);

      // Define animation handler for scrolling.
      var disappearsHandler = function disappearsHandler() {
        $(self.disappearsSelector).not('.animation-processed').each(function (i, item) {
          var $item = $(item);
          var offset = $item.data(offsetAttribute) || self.paramOffsetDefault;
          if ($item.isInViewport(offset)) {
            var direction = $item.data(directionAttribute) || self.paramDirectionDefault;
            var delay = $item.data(delayAttribute) || self.paramDelayDefault;
            var applyToChildren = $item.data(childrenAttribute) || false;

            if (!applyToChildren) {
              // Apply animation to element.
              setTimeout(function () {
                $item.addClass(self.disappearsClassPrefix + '-' + direction + ' animation-processed');
              }, delay);
            } else {
              // Apply animation to elements's children.
              var $children = $item.children();
              $item.removeClass(initialClass).addClass('animation-processed');
              $children.addClass(initialClass).each(function (c, child) {
                setTimeout(function () {
                  $(child).addClass(self.disappearsClassPrefix + '-' + direction);
                }, delay * c);
              });
            }
          }
        });
      };

      // On window scroll.
      $(window).off('scroll.disappears.animations').on('scroll.disappears.animations', disappearsHandler).off('resize.disappears.animations').on('resize.disappears.animations', disappearsHandler);

      // On first load.
      disappearsHandler();
    }
  }]);

  return AnimationsController;
}();