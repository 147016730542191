'use strict';

/**
 * Layout_display_Helpers Class.
 * @author Brais Saco Estévez
 *
 *
 * JS Manager for Layout Display Helper
 *
 * @return {Object} New instance
 *
 */

/**
 * Layout Helper Tools
 *
 * @param {string} layoutDisplaySettings Initialization settings
 * @return {Window.LayoutDisplayHelpers} object
 * @constructor
 */
window.LayoutDisplayHelpers = function (layoutDisplaySettings) {

  var self = this;

  /*
   * ATTRIBUTES
   * **************************************************************************
   */

  /** Configuration data * */
  self.CONFIG = {};
  self.active = true;

  /** Site information * */
  self.siteName = '';
  self.siteNameAcronym = '';

  /** This class name * */
  self.className = 'LayoutDisplayHelpers';

  /** UI elements * */
  self.ui = {};

  /** Animation parameters * */
  self.animate = {
    initial_delay: 200,
    fade_timing: 300
  };

  /** State of the displays wrapper: open or closed * */
  self.open = 1;

  /*
   * PRIVATE METHODS
   * **************************************************************************
   */

  /**
   * Checks for wrapper state: open or closed.
   */
  var checkState = function checkState() {
    if (!localStorage) return;
    var state = localStorage.getItem('ld_state');
    if (state != null) self.open = state;
  };

  /**
   * Initializes Configuration parameters.
   */
  var initConfig = function initConfig() {
    if (layoutDisplaySettings) {
      self.CONFIG = layoutDisplaySettings;
    }

    // Display Helpers activation.
    if (!self.CONFIG.active) {
      self.active = false;
    } else if (jQuery.type(self.CONFIG.active) === 'string') {
      if (self.CONFIG.active !== layoutDisplaySettings.env) {
        self.active = false;
      }
    } else {
      self.active = true;
    }

    // Site Info Label
    if (self.CONFIG.helpers.site_info.label) {
      self.siteName = self.CONFIG.helpers.site_info.label;
      /*
      } else if (!!layoutDisplaySettings.info.site_name) {
      self.siteName = layoutDisplaySettings.info.site_name;
      */
    } else {
      self.siteName = window.location.host;
    }

    // Site Info Acronym
    if (self.CONFIG.helpers.site_info.acronym) {
      self.siteNameAcronym = self.CONFIG.helpers.site_info.acronym;
    } else {
      self.siteNameAcronym = getAcronym();
    }
  };

  /**
   * Initializes UI elements.
   */
  var initUi = function initUi() {
    initLayoutHelper();
    initSiteInformationHelper();
    initWindowMeasuresHelper();
    initWindowTitle();
    initButtons();

    // Hide helpers if persistent state is 'hidden'.
    if (self.open === '0' && self.ui.toggleButton && self.ui.toggleButton.length) {
      hideWrapper(false);
    }

    // Show wrappper.
    self.ui.wrapper.delay(self.animate.initial_delay).fadeIn(self.animate.fade_timing);
  };

  /**
   * Initializes Helpers' main wrapper.
   */
  var initLayoutHelper = function initLayoutHelper() {
    if (!self.CONFIG.helpers.breakpoints.active) {
      $('body').addClass('layd-off');
    }

    self.ui.wrapper = $('<div></div>', {
      id: 'layd-wrapper'
    }).appendTo('body');
  };

  /**
   * Initializes Site information display.
   */
  var initSiteInformationHelper = function initSiteInformationHelper() {
    if (!self.CONFIG.helpers.site_info.active) return;
    self.ui.siteInformation = $('<div></div>', {
      id: 'layd-site-info'
    }).append('[' + self.siteNameAcronym + '] ' + self.siteName).appendTo(self.ui.wrapper);
  };

  /**
   * Initializes Window Measures display.
   */
  var initWindowMeasuresHelper = function initWindowMeasuresHelper() {
    if (!self.CONFIG.helpers.measures.active) return;
    self.ui.windowMeasures = $('<div></div>', {
      id: 'layd-measures-info'
    }).appendTo(self.ui.wrapper);
  };

  /**
   * Initializes window title.
   */
  var initWindowTitle = function initWindowTitle() {
    if (!self.CONFIG.helpers.site_info.active) return;
    document.title = '[' + self.siteNameAcronym + '] ' + document.title;
  };

  /**
   * Initializes action buttons.
   */
  var initButtons = function initButtons() {
    // Toggle visibility of display's helpers if there's something to hide/show.
    var totalOff = $('body').hasClass('layd-total-off');
    var screenDspOff = $('body').hasClass('layd-off');
    var numDisplays = self.ui.wrapper.children().length;

    if (!totalOff && (!screenDspOff || numDisplays)) {
      self.ui.toggleButton = $('<div></div>', {
        id: 'layd-toogle-btn'
      }).click(function () {
        if (!self.open) showWrapper();else hideWrapper();
      });
      self.ui.wrapper.append(self.ui.toggleButton);
    }
  };

  /**
   * Crete site name acronym.
   *
   * @return {string} Site acronym
   */
  var getAcronym = function getAcronym() {
    return self.siteName.replace(new RegExp(' ', 'g'), '').substring(0, 6).toUpperCase();
  };

  /**
   * Initializes UI elements dynamic behaviours.
   */
  var initBehaviour = function initBehaviour() {
    initWindowMeasuresBehaviour();
  };

  /**
   * Initializes Window measures behaviour.
   */
  var initWindowMeasuresBehaviour = function initWindowMeasuresBehaviour() {
    if (!self.CONFIG.helpers.measures.active) return;
    setWindowMeasuresOnDisplay();
    $(window).off('resize.layd').on('resize.layd', setWindowMeasuresOnDisplay);
  };

  /**
   * Write the size on Window measures display.
   */
  var setWindowMeasuresOnDisplay = function setWindowMeasuresOnDisplay() {
    var measures = getWindowMeasures();
    self.ui.windowMeasures.text(measures[0] + ' x ' + measures[1]);
  };

  /**
   * Get actual size of the window.
   *
   * @return {Array} Window size (Width, Height)
   */
  var getWindowMeasures = function getWindowMeasures() {
    var width = window.innerWidth;
    var height = window.innerHeight;
    return [width, height];
  };

  /**
   * Hide helper's wrapper.
   *
   * @param {Boolean} animation Animate Ui wrapper
   */
  var hideWrapper = function hideWrapper(animation) {
    var animate = typeof animation === 'undefined' ? true : animation;
    var timing = animate ? self.animate.fade_timing : 0;
    self.ui.wrapper.addClass('layd-hidden');
    self.ui.toggleButton.addClass('to-show');
    self.ui.wrapper.children().not(self.ui.toggleButton).fadeOut(timing);
    self.open = 0;
    if (localStorage) localStorage.setItem('ld_state', self.open);
  };

  /**
   * Show helper's wrapper.
   *
   * @param {Boolean} animation Animate Ui wrapper
   */
  var showWrapper = function showWrapper(animation) {
    var animate = typeof animation === 'undefined' ? true : animation;
    var timing = animate ? self.animate.fade_timing : 0;
    self.ui.wrapper.removeClass('layd-hidden');
    self.ui.toggleButton.removeClass('to-show');
    self.ui.wrapper.children().not(self.ui.toggleButton).fadeIn(timing);
    self.open = 1;
    if (localStorage) localStorage.setItem('ld_state', self.open);
  };

  /*
   * PUBLIC METHODS
   * **************************************************************************
   */

  /**
   * Initialize this Class.
   */
  self.init = function () {
    checkState();
    initConfig();
    if (!self.active) {
      $('body').addClass('layd-total-off');
    }
    var show = !$('body').hasClass('layd-total-off');
    if (!show) return;
    if (self.active) {
      initUi();
      initBehaviour();
    }
  };

  /* SELF INIT CLASS */
  self.init();

  return self;
};