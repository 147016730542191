/**
 * Directus Wrapper main JS base file.
 *
 * @author Brais Saco Estévez
 * @extends Base
 * @lint global DirectusWrapper
 */

window.DirectusWrapper = class DirectusWrapper extends Base {

  // =========================================================================
  // INITIALIZATION
  // =========================================================================

  /**
   * @override
   * @see BaseItem
   */
  _initAttributes(api) {

    /**
     * Call Parent constructor.
     */
    super._initAttributes(api);

    // Initialize Directus JS SDK
    window.dataApi = new DirectusSDK(api);

  }

};
