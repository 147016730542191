"use strict";

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _get = function get(object, property, receiver) { if (object === null) object = Function.prototype; var desc = Object.getOwnPropertyDescriptor(object, property); if (desc === undefined) { var parent = Object.getPrototypeOf(object); if (parent === null) { return undefined; } else { return get(parent, property, receiver); } } else if ("value" in desc) { return desc.value; } else { var getter = desc.get; if (getter === undefined) { return undefined; } return getter.call(receiver); } };

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

/**
 * Directus relation between collections Base Model
 *
 * @author Brais Saco Estévez
 * @abstract
 * @extends Base
 * @lint global BaseRelation
 */
window.BaseRelation = function (_Base) {
  _inherits(BaseRelation, _Base);

  function BaseRelation() {
    _classCallCheck(this, BaseRelation);

    return _possibleConstructorReturn(this, (BaseRelation.__proto__ || Object.getPrototypeOf(BaseRelation)).apply(this, arguments));
  }

  _createClass(BaseRelation, [{
    key: "_initAttributes",


    // =========================================================================
    // INITIALIZATION
    // =========================================================================

    value: function _initAttributes() {
      var _get2;

      for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
        args[_key] = arguments[_key];
      }

      (_get2 = _get(BaseRelation.prototype.__proto__ || Object.getPrototypeOf(BaseRelation.prototype), "_initAttributes", this)).call.apply(_get2, [this].concat(args));

      /**
       * Manager class for the relational collection
       * @type {*}
       */
      this.relatedCollection = null;

      /**
       * This relation is unique or not.
       * @type {boolean}
       */
      this.unique = false;

      /**
       * Link field for X2M relations.
       * @type {string|false}
       */
      this.linkField = false;

      // Retrieve arguments from the constructor.
      if (args[0]) {

        var RelatedCollection = args[0];

        this.relatedCollection = new RelatedCollection();
      }
    }

    // =========================================================================
    // PUBLIC METHODS
    // =========================================================================

    /**
     * Retrieve link field.
     * @return {string|false} Link field or false if not exists.
     */

  }, {
    key: "getLinkField",
    value: function getLinkField() {
      return this.linkField;
    }

    /**
     * Return API query's fields parameter value for the relation.
     * @return {string[]} List of fields for API query
     */

  }, {
    key: "getQueryFields",
    value: function getQueryFields() {
      return this.relatedCollection.getQueryFields();
    }

    /**
     * Retrieve relation's mapped fields.
     * @param {object|array} rawRelationFieldValue Raw value for relation field.
     * @return {object|array} mapped values.
     */

  }, {
    key: "getRelationFields",
    value: function getRelationFields(rawRelationFieldValue) {
      var rawRelationCollection = _.isArray(rawRelationFieldValue) ? rawRelationFieldValue : [rawRelationFieldValue];
      if (this.unique) {
        rawRelationCollection = _.take(rawRelationCollection); // takes first element.
      }
      var mapped = this.relatedCollection.mapItemsField(rawRelationCollection, this.linkField);
      return this.unique ? mapped.pop() : mapped;
    }
  }]);

  return BaseRelation;
}(Base);