'use strict';

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

/**
 * Class for single data binding management. Converts Markdown data into HTML data.
 *
 * @author Brais Saco Estévez
 * @requires showdown
 * @url https://github.com/showdownjs/showdown
 *
 * @lint global Binder
 */
window.Binder = function () {

  /**
   * Class constructor.
   * @param {object} config Configuration object
   */
  function Binder(config) {
    _classCallCheck(this, Binder);

    if (window.bindManager) return window.bindManager;

    this.bindSelector = config.bindSelector || '[data-bind]';

    this.bindLoopSelector = config.bindLoopSelector || '[data-bind-loop]';

    this.bindTemplateRegExp = config.bindTemplateRegExp || /{{[\s]*(.+?)[\s]*}}/ig;

    this.showdown = new window.showdown.Converter(config.showdown);
  }

  /**
   * Bind data to actual HTML view.
   * @param {Object} data Values to bind to HTML
   * @param {string|jQuery} scope Main DOM wrapper to apply data binding
   */


  _createClass(Binder, [{
    key: 'apply',
    value: function apply(data) {
      var scope = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'body';


      this.restoreOriginalHtml(scope);

      this.bindLoop(data, scope); // It may be the first.

      this.bindTemplate(data, scope);

      this.bindAttribute(data, scope);
    }

    /**
     * Bind data to actual HTML view using template references.
     * @param {Object} data Values to bind to HTML
     * @param {string|jQuery} scope Main DOM wrapper to apply data binding
     */

  }, {
    key: 'bindTemplate',
    value: function bindTemplate(data) {
      var scope = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'body';


      if (!data) {
        console.error('Binder => No data to bind');
        return;
      }

      var self = this;
      var processedHtml = $(scope).html().replace(self.bindTemplateRegExp, function (matched, dataReference) {
        var content = self.searchInObject(dataReference, data);
        var html = self.parseToHtml(content);
        return html;
      });

      $(scope).html(processedHtml);
    }

    /**
     * Bind data to actual HTML view using DOM element attribute binding
     * @param {Object} data Values to bind to HTML
     * @param {string|jQuery} scope Main DOM wrapper to apply data binding
     */

  }, {
    key: 'bindAttribute',
    value: function bindAttribute(data) {
      var scope = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'body';


      if (!data) {
        console.error('Binder => No data to bind');
        return;
      }

      var self = this;

      $(scope).find(this.bindSelector).each(function (idx, elem) {
        var $elem = $(elem);
        var bindRef = $elem.data('bind');
        var bindProcess = $elem.data('data-bind-process') || false;
        var content = self.searchInObject(bindRef, data);
        if (!!content && !bindProcess) {
          var html = self.parseToHtml(content);
          $elem.html(html).data('data-bind-process', true);
        }
      });
    }

    /**
     * Create children for a DOM element using collection.
     * @param {Object} data Values to bind to HTML
     * @param {string|jQuery} scope Main DOM wrapper to apply data binding
     */

  }, {
    key: 'bindLoop',
    value: function bindLoop(data, scope) {

      if (!data) {
        console.error('Binder => No data to bind');
        return;
      }

      var self = this;

      $(scope).find(this.bindLoopSelector).each(function (idx, elem) {
        var $elem = $(elem);
        var elemInnerHtml = $elem.html();
        var bindRef = $elem.data('bind-loop');
        var bindProcess = $elem.data('data-bind-loop-process') || false;
        var content = data;
        if (bindRef) {
          content = self.searchInObject(bindRef, data);
        }

        if (!!content && !bindProcess) {
          $elem.empty().data('data-bind-loop-process', true);

          content.forEach(function (rowData) {
            var $innerContent = $(elemInnerHtml);
            $elem.append($innerContent);
            self.bindAttribute(rowData, $innerContent);
            self.bindTemplate(rowData, $innerContent);
          });
        }
      });
    }

    /**
     * Retrieve object reference.
     * @param {string} reference Point separated reference
     * @param {Object} dataObject Object to find in.
     * @return {object|string} Referenced value in object
     */

  }, {
    key: 'searchInObject',
    value: function searchInObject(reference, dataObject) {
      try {
        return reference.split('.').reduce(function (a, v) {
          return a[v];
        }, dataObject);
      } catch (error) {
        console.warn('No reference found', reference, dataObject);
        return '[' + reference + ' : N/A]';
      }
    }

    /**
     * Converts markdown to HTML.
     * @param {string} contents Markdown contents
     * @return {string} HTML contents
     */

  }, {
    key: 'parseToHtml',
    value: function parseToHtml(contents) {

      // Convert markdown to HTML
      var html = this.showdown.makeHtml(contents);

      // Unwrap single <p> elements.
      var $wrapper = $('<div></div>').html($(html));
      if ($wrapper.children().length === 1) {
        html = $(html).unwrap('p').html();
      }

      return html;
    }

    /**
     * Store original HTML contents into DOM object data.
     * @param {string|jQuery} scope DOM element
     */

  }, {
    key: 'storeOriginalHtml',
    value: function storeOriginalHtml(scope) {
      var originalHtml = $(scope).html();
      $(scope).data('bind-original', originalHtml);
    }

    /**
     * Restore original HTML contents from DOM object data or stores it if doesn't exists yet.
     * @param {string|jQuery} scope DOM element
     */

  }, {
    key: 'restoreOriginalHtml',
    value: function restoreOriginalHtml(scope) {
      var originalHtml = $(scope).data('bind-original');
      if (!originalHtml) {
        this.storeOriginalHtml(scope);
      } else {
        $(scope).html(originalHtml);
      }
    }
  }]);

  return Binder;
}();