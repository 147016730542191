'use strict';

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

/**
 * Class that manages main overlay.
 * @extends BasePieceController
 *
 * @lint global MainOverlayController
 */
window.MainOverlayController = function (_BasePieceController) {
  _inherits(MainOverlayController, _BasePieceController);

  function MainOverlayController() {
    _classCallCheck(this, MainOverlayController);

    var _this = _possibleConstructorReturn(this, (MainOverlayController.__proto__ || Object.getPrototypeOf(MainOverlayController)).call(this, '.', 'main-overlay'));

    _this.showClass = 'show';

    _this.init();

    return _this;
  }

  _createClass(MainOverlayController, [{
    key: 'isVisible',
    value: function isVisible() {

      return this.dom.hasClass(this.showClass);
    }
  }, {
    key: 'show',
    value: function show() {
      var lockScroll = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;


      this.dom.addClass(this.showClass);

      if (lockScroll) {

        this.toggleLockScroll(true);
      }
    }
  }, {
    key: 'hide',
    value: function hide() {
      var lockScroll = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;


      this.dom.removeClass(this.showClass);

      if (lockScroll) {

        this.toggleLockScroll(false);
      }
    }
  }, {
    key: 'toggle',
    value: function toggle() {
      var lockScroll = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;


      this.dom.toggleClass(this.showClass);

      if (lockScroll) {

        this.toggleLockScroll(this.isVisible());
      }
    }
  }, {
    key: 'toggleLockScroll',
    value: function toggleLockScroll() {
      var lockBody = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;


      var bodyOverflow = lockBody ? 'hidden' : '';

      $('body, html').css('overflow-y', bodyOverflow);
    }
  }]);

  return MainOverlayController;
}(BasePieceController);