'use strict';

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

/**
 * Class that manages page links with special behaviours
 * @requires jQuery
 *
 * @lint global LinksController
 */
window.LinksController = function () {
  function LinksController() {
    _classCallCheck(this, LinksController);

    this.init();
  }

  _createClass(LinksController, [{
    key: 'init',
    value: function init() {

      this.initAnchors();
    }

    /**
     * Initialize DOM elements behaviour.
     */

  }, {
    key: 'initAnchors',
    value: function initAnchors() {

      $('a[href^="#"]').not('a[href="#"]').off('click.links.controller').on('click.links.controller', function (ev) {

        ev.preventDefault();
        var $item = $(ev.currentTarget);
        var target = $item.attr('href').replace('#', '');

        var $target = $('a[name=\'' + target + '\'');
        $.scrollTo($target);
      });
    }
  }]);

  return LinksController;
}();