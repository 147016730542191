/**
 * Class that manages main loader.
 * @extends BasePieceController
 *
 * @lint global MainLoaderController
 */
window.MainLoaderController = class MainLoaderController extends BasePieceController {

  constructor(config = {}) {

    super('.', 'main-loader');

    this.showClass = 'show';

    this.overlayManager = config.overlayManager || new MainOverlayController();

    this.init();

  }

  isVisible() {

    return this.dom.hasClass(this.showClass);

  }

  show(lockScroll = false) {

    this.dom.addClass(this.showClass);

    this.overlayManager.show(lockScroll);

  }

  hide(lockScroll = false) {

    this.dom.removeClass(this.showClass);

    this.overlayManager.hide(lockScroll);

  }

  toggle(lockScroll = false) {

    this.dom.toggleClass(this.showClass);

    this.overlayManager.toggle(lockScroll);

  }


};
