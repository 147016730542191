'use strict';

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _toConsumableArray(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) { arr2[i] = arr[i]; } return arr2; } else { return Array.from(arr); } }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

/**
 * Main Class for site management
 * @extends BasePageController
 *
 * @lint global HomePageController
 */
window.SiteController = function () {

  /**
   * Class constructor.
   * @param {Object} siteConfig Site main configuration
   */
  function SiteController(siteConfig) {
    _classCallCheck(this, SiteController);

    this.config = siteConfig;

    this.lang = false;

    this.managers = {};

    this.dataCalls = [];

    this.init();
  }

  /**
   * Main initialization.
   */


  _createClass(SiteController, [{
    key: 'init',
    value: function init() {

      this.initEvents();

      this.initGlobalManagers();

      this.initMainUi();

      this.initData();
    }

    /**
     * Initialize events
     */

  }, {
    key: 'initEvents',
    value: function initEvents() {
      var _this = this;

      $(document).on('site:data:ready', function () {
        _this.initUi();
      });
    }

    /**
     * Initialize global managers.
     */

  }, {
    key: 'initGlobalManagers',
    value: function initGlobalManagers() {

      // Layout Display Initialization.
      window.layoutDisplay = new LayoutDisplayHelpers(this.config.layoutDisplay);

      // Layout Manager Initialization.
      window.layoutManager = new LayoutManager(this.config.ui.layout);

      // Data Manager initialization.
      window.dataManager = new Data(this.config.api);

      // Data Binder initialization.
      window.bindManager = new Binder(this.config.binder);
    }

    /**
     * Initialize global UI site managers before data loading.
     */

  }, {
    key: 'initMainUi',
    value: function initMainUi() {

      // Retrieve stored language or default language.
      var selectedLanguage = window.store.get('lang');

      if (!selectedLanguage) {
        selectedLanguage = this.config.lang.default;
      }

      this.setLanguage(selectedLanguage);

      // Initialize UI main elements.
      this.managers.overlayManager = new MainOverlayController();

      this.managers.loaderManager = new MainLoaderController({
        overlayManager: this.managers.overlayManager
      });
    }

    /**
     * Initialize site contents.
     */

  }, {
    key: 'initData',
    value: function initData() {
      var _$,
          _this2 = this;

      this.managers.loaderManager.show(true);

      var webData = {};
      var sectionData = {};
      var textData = {};

      var webCollectionManager = new WebCollection();
      var sectionCollectionManager = new SeccionCollection();
      var textCollectionManager = new TextoCollection();

      var mainMenuController = new MainMenuBlockController();
      var footerController = new FooterBlockController();
      var langSelectorController = new LangSelectorBlockController();
      var homePageController = new HomePageController();

      // Retrieve Web collection. -------------------------------------------------
      this.dataCalls.push(webCollectionManager.getItems().then(function (collectionData) {
        webData = webCollectionManager.mapItemsField(collectionData.data).pop();
      }));

      // Retrieve Sections collection ---------------------------------------------
      this.dataCalls.push(sectionCollectionManager.getItems().then(function (collectionData) {
        sectionData = sectionCollectionManager.mapItemsField(collectionData.data);
      }));

      // Retrieve Text collection ---------------------------------------------
      this.dataCalls.push(textCollectionManager.getItems().then(function (collectionData) {
        textData = textCollectionManager.mapItemsField(collectionData.data);
      }));

      // When all data is retrieved. => SITE DATA IS READY
      (_$ = $).when.apply(_$, _toConsumableArray(this.dataCalls)).done(function () {

        // Bind data to DOM elements.
        footerController.bindData(webData);
        langSelectorController.bindData(webData.idiomas);
        mainMenuController.bindData(sectionData);
        homePageController.bindData({
          sections: sectionData,
          texts: textData
        });

        // End data calls.
        _this2.managers.loaderManager.hide(true);
        $(document).triggerHandler('site:data:ready');
        console.log('Site Data Ready');
      });
    }

    /**
     * Initialize UI elements before data loading.
     */

  }, {
    key: 'initUi',
    value: function initUi() {

      this.managers.animationsManager = new AnimationsController(this.config.ui.animations);

      this.managers.linksManager = new LinksController();

      this.managers.mainNavBlockController = new MainNavBlockController();
    }

    /**
     * Set global language of the site.
     * @param {string} lang Language code
     */

  }, {
    key: 'setLanguage',
    value: function setLanguage(lang) {

      this.lang = lang;

      $('html').attr('lang', lang);

      window.store.set('lang', lang);

      window.lang = lang; // Global variable -> for directus.
    }

    /**
     * Change site's contents language
     * @param {string} lang Language code
     */

  }, {
    key: 'changeLanguage',
    value: function changeLanguage(lang) {

      if (this.lang === lang) return;

      this.setLanguage(lang);

      this.initData();
    }
  }]);

  return SiteController;
}();