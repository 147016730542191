'use strict';

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _get = function get(object, property, receiver) { if (object === null) object = Function.prototype; var desc = Object.getOwnPropertyDescriptor(object, property); if (desc === undefined) { var parent = Object.getPrototypeOf(object); if (parent === null) { return undefined; } else { return get(parent, property, receiver); } } else if ("value" in desc) { return desc.value; } else { var getter = desc.get; if (getter === undefined) { return undefined; } return getter.call(receiver); } };

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

/**
 * Directus Collection Model: 'Textos'
 *
 * @author Brais Saco Estévez
 * @extends BaseCollection
 * @lint global TextoCollection
 */
window.TextoCollection = function (_BaseCollection) {
  _inherits(TextoCollection, _BaseCollection);

  function TextoCollection() {
    _classCallCheck(this, TextoCollection);

    return _possibleConstructorReturn(this, (TextoCollection.__proto__ || Object.getPrototypeOf(TextoCollection)).apply(this, arguments));
  }

  _createClass(TextoCollection, [{
    key: '_initAttributes',


    // =========================================================================
    // INITIALIZATION
    // =========================================================================

    /**
     * @override
     * @see BaseCollection
     */
    value: function _initAttributes() {
      var _get2;

      for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
        args[_key] = arguments[_key];
      }

      (_get2 = _get(TextoCollection.prototype.__proto__ || Object.getPrototypeOf(TextoCollection.prototype), '_initAttributes', this)).call.apply(_get2, [this].concat(args));

      this.collectionName = 'textos';

      this.queryFields = ['id', 'identificador'];

      this.MODEL = TextoItem;
    }

    // =========================================================================
    // PUBLIC METHODS
    // =========================================================================

    /**
     * Get all the texts of a section
     * @param {string} section Section identifier
     * @param {boolean} filterByLang Filter texts by language or retrieve all languages.
     * @return {Promise|*} API Promise
     */

  }, {
    key: 'getItemsBySection',
    value: function getItemsBySection(section) {
      var filterByLang = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : true;

      var filter = null;
      if (section) {
        filter = {
          'seccion.identificador': section
        };
      }
      return this.getItems(filter, {}, true, filterByLang);
    }
  }]);

  return TextoCollection;
}(BaseCollection);