/**
 * Class for Main nav block management
 * @extends BaseBlockController
 *
 * @lint global MainNavBlockController
 */
window.MainNavBlockController = class MainNavBlockController extends BaseBlockController {

  /**
   * Class constructor.
   */
  constructor() {

    super('main-nav');

    this.scrollLayoutClass = 'scrolling';

    this.scrollLayoutOffset = 80;

  }

  /**
   * @override
   */
  initUi() {

    super.initUi();

    this.ui.navbarToggler = this.dom.find('.navbar-toggler');

    this.ui.navbarTogglerButton = this.ui.navbarToggler.find('.navbar-toggler-icon');

  }

  /**
   * @override
   */
  initEvents() {

    $(window)
      .off('scroll.main-nav.block')
      .on('scroll.main-nav.block', () => {
        this.setScrollLayout();
      });

    $(document)
      .ready(() => {
        this.setScrollLayout();
      });

  }

  /**
   * @override
   */
  initBehaviour() {

    this.initNavbarToggler();

  }

  /**
   * Set layout change of navbar on scrolling event.
   */
  setScrollLayout() {

    let self = this;
    let actualScroll = $(window)
      .scrollTop();
    self.dom.toggleClass(self.scrollLayoutClass, (actualScroll > self.scrollLayoutOffset));

  }

  /**
   * Initialize main navbar toggle button.
   */
  initNavbarToggler() {

    let self = this;
    this.ui.navbarToggler
      .off('click.main-nav.block')
      .on('click.main-nav.block', () => {
        self.ui.navbarTogglerButton.toggleClass('open');
      });

  }

};
