'use strict';

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _toConsumableArray(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) { arr2[i] = arr[i]; } return arr2; } else { return Array.from(arr); } }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

/**
 * Base Class with common functions.
 *
 * @author Brais Saco Estévez
 * @abstract
 * @lint global Base
 */
window.Base = function () {

  /**
   * Class Constructor function.
   */
  function Base() {
    _classCallCheck(this, Base);

    /**
     * Assign instance attributes.
     */
    this._initAttributes.apply(this, arguments);

    /**
     * Call to instantiation processes.
     */
    this._initInstance.apply(this, arguments);
  }

  // =========================================================================
  // INITIALIZATION
  // =========================================================================

  /**
   * Initialize attributes.
   */


  _createClass(Base, [{
    key: '_initAttributes',
    value: function _initAttributes() {

      /**
       *  Debug mode activation
       */
      this.DEBUG = window.siteConfig && window.siteConfig.debug ? window.siteConfig.debug : false;

      /**
       * This class name
       */
      this.className = this.getClassName();

      /**
       * Use for store anything
       */
      this.memory = {};
    }

    /**
     * Initialize this instance.
     */

  }, {
    key: '_initInstance',
    value: function _initInstance() {
      if (this.DEBUG === 'full') {
        for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
          args[_key] = arguments[_key];
        }

        this.log('Initialize _' + this.className + '_ instance', args);
      }
    }

    // =========================================================================
    // PUBLIC METHODS
    // =========================================================================

    /**
     * Return Class Name
     *
     * @return {string} The class name
     */

  }, {
    key: 'getClassName',
    value: function getClassName() {
      return this.constructor.toString().split('(' || /s+/)[0].split(' ' || /s+/)[1];
    }

    /**
     * Class for debug logging into JS console.
     */

  }, {
    key: 'log',
    value: function log() {
      if (!this.DEBUG) {
        return;
      }
      var className = this.className;

      for (var _len2 = arguments.length, args = Array(_len2), _key2 = 0; _key2 < _len2; _key2++) {
        args[_key2] = arguments[_key2];
      }

      var params = [].slice.apply(args);
      var caller = window[className].getLineNumber();
      var callerName = caller.name || '';
      callerName = callerName.replace(className + '.', '');

      if (window.log) {
        var info = '[c=\'color:#336699; font-weight:bold\'][' + className + '][c]';
        if (callerName) {
          info += '[c=\'color:#538bc3;\'][' + callerName + '][c]: ';
        }
        window.log(info);
        window.log.apply(window.log, params);
      } else {
        var _console;

        if (callerName) {
          params.unshift('[' + className + '][' + callerName + '] => Line ' + caller.line + '\n'); // eslint-disable-line
        } else {
          params.unshift('[' + className + '] => Line ' + caller.line + '\n'); // eslint-disable-line
        }
        (_console = console).log.apply(_console, _toConsumableArray(params)); // eslint-disable-line no-console
      }
    }

    // =========================================================================
    // STATIC METHODS
    // =========================================================================

    /**
     * Returns line number of the caller function
     *
     * @return {Object} Function and line number information.
     */

  }], [{
    key: 'getLineNumber',
    value: function getLineNumber() {

      /**
       * Get basic error object
       *
       * @return {Error} Error object
       */
      function getErrorObject() {
        try {
          throw Error('');
        } catch (err) {
          return err;
        }
      }

      var err = getErrorObject();
      var caller = 'unknown';
      var number = 'unknown';
      if (typeof err.stack !== 'undefined') {
        var callerLine = err.stack.split('\n')[5];
        var pieces = callerLine.split(':');
        number = pieces[pieces.length - 2];
        var findCaller = pieces[0].split('at ');
        caller = findCaller[1] ? findCaller[1].split(' ')[0] : false;
      }
      return {
        line: number,
        name: caller
      };
    }
  }]);

  return Base;
}();