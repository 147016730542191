'use strict';

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

/**
 * Layouts Manager Class.
 *
 * @author Brais Saco Estévez
 * @lint global LayoutManager
 *
 * Class that manages UI layout changes.
 */
window.LayoutManager = function () {

  /**
   * Class Constructor function.
   * @param {Object} settings Settings object
   *
   * BP information: {label, [min], [max]}
   */
  function LayoutManager(settings) {
    _classCallCheck(this, LayoutManager);

    /**
     * DOM references.
     * @type {Object}
     */
    this.ui = {};

    /**
     * Miscellanous store
     * @type {Object}
     */
    this.memory = {};

    /**
     * Page structure established
     *  @type {boolean}
     */
    this.actualLayout = false;

    /**
     *  Device orientation established
     *  @type {boolean}
     */
    this.actualOrientation = false;

    /**
     * Defines Mobile breakpoint
     * @type {string}
     */
    this.mobileBreakpoint = settings && settings.mobileBreakpoint || 'XS';

    /**
     * Devices limit measurements (Bootstrap 3)
     * @type {Object}
     */
    this.layouts = settings && settings.breakpoints || {
      retina: {
        label: 'RT',
        min: 321,
        max: 480
      },
      mobile: {
        label: 'XS',
        min: 530,
        max: 767
      },
      tablet: {
        label: 'SM',
        min: 768,
        max: 991
      },
      medium: {
        label: 'MD',
        min: 992,
        max: 1199
      },
      desktop: {
        label: 'LG',
        min: 1200,
        max: 9999
      }
    };

    this.init();
  }

  // =========================================================================
  // INITIALIZATION
  // =========================================================================

  /**
   * Initialize this instance.
   * @private
   */


  _createClass(LayoutManager, [{
    key: 'init',
    value: function init() {
      this._initUi();
      this._initEvents();
      this._checkLayout();
      this._checkOrientation();
    }

    /**
     * Initialize Layout UI elements.
     * @private
     */

  }, {
    key: '_initUi',
    value: function _initUi() {
      this._initLayoutTexts();
    }

    /**
     * Initialize General Page & Window events.
     * @private
     */

  }, {
    key: '_initEvents',
    value: function _initEvents() {
      var self = this;

      $(document).off('layoutChange.layout.manager').on('layoutChange.layout.manager', function () {
        self._setLayoutTexts();
      });
      $(window).off('resize.layout.manager').on('resize.layout.manager', function () {
        self._checkLayout();
      });
      $(window).off('orientationchange.layout.manager').on('orientationchange.layout.manager', function () {
        self._checkOrientation();
      });
    }

    // =========================================================================
    // API METHODS
    // =========================================================================

    /**
     * Returns site's actual layout.
     *
     * @return {String} Actual layout's label.
     */

  }, {
    key: 'getActualLayout',
    value: function getActualLayout() {
      return this.actualLayout;
    }

    /**
     * Returns actual layout's orientation.
     *
     * @return {String} 'L': landscape, 'P': Portrait.
     */

  }, {
    key: 'getActualOrientation',
    value: function getActualOrientation() {
      return this.actualOrientation;
    }

    /**
     * Checks if actual layout is equal to parameter.
     *
     * @param {String} label Layout label to check
     * @return {Boolean} True if layout label matches
     */

  }, {
    key: 'isLayout',
    value: function isLayout(label) {
      return this.actualLayout === label;
    }

    /**
     * Checks if actual layout's orientation is 'portrait'.
     *
     * @return {Boolean} True if orientation is 'portrait'
     */

  }, {
    key: 'isPortrait',
    value: function isPortrait() {
      return this.actualOrientation === 'P';
    }

    /**
     * Returns actual layout's orientation is 'landscape'.
     *
     * @return {Boolean} True if orientation is 'portrait'
     */

  }, {
    key: 'isLandscape',
    value: function isLandscape() {
      return this.actualOrientation === 'L';
    }

    /**
     * Returns true if actual layout is in a mobile layout dimensions.
     *
     * @return {String|false} Mobile layout or not
     */

  }, {
    key: 'isMobile',
    value: function isMobile() {
      var labelInfo = this.getLayoutInfoByLabel(this.mobileBreakpoint);
      return this.isInLayout(labelInfo.id);
    }

    /**
     * Add element to "Layout Texts" set for behaviour applying.
     *
     * @param {jQuery} $items Items to add.
     * @param {Object} data Layout text info
     */

  }, {
    key: 'addToLayoutText',
    value: function addToLayoutText($items, data) {
      if (!data) return;
      $items.each(function (i, item) {
        var $it = $(item);
        LayoutManager.initInitialLayoutText($it);
        $it.data('layoutText', data);
      });
      this.ui.$layoutTextItems = self.ui.$layoutTextItems.add($items);
      this._setLayoutTexts();
    }

    /**
     * Checks if a layout's maximum width is content on another layout's width.
     *
     * @param {String} lay1 Layout to search
     * @param {String} lay2 Layout to compare
     * @return {Boolean} true if lay1 is minus than lay2
     */

  }, {
    key: 'isMinusThan',
    value: function isMinusThan(lay1, lay2) {
      if (!lay1) {
        return false;
      }
      lay2 = lay2 || this.actualLayout;

      var lay1Data = false;
      var lay2Data = false;

      $.each(this.layouts, function (index, lay) {
        if (lay.label === lay1) {
          lay1Data = lay;
        }
      });
      $.each(this.layouts, function (index, lay) {
        if (lay.label === lay2) {
          lay2Data = lay;
        }
      });
      return parseInt(lay1Data.max, 10) <= parseInt(lay2Data.max, 10);
    }

    /**
     * Retrieve Layout information
     * @param {String} layoutId layout Identifier
     * @return {Object} Layout information.
     */

  }, {
    key: 'getLayoutInfo',
    value: function getLayoutInfo(layoutId) {
      return this.layouts[layoutId];
    }

    /**
     * Retrieve Layout information using its label field
     * @param {String} layoutLabel layout label
     * @return {Object} Layout information.
     */

  }, {
    key: 'getLayoutInfoByLabel',
    value: function getLayoutInfoByLabel(layoutLabel) {
      var toRet = false;
      $.each(this.layouts, function (i, layout) {
        if (layout.label === layoutLabel) {
          toRet = layout;
          toRet.id = i;
        }
      });
      return toRet;
    }

    /**
     * Checks if site's screen resolution is included in a concrete layout.
     * @param {Object| String} layout Layout information or layout id.
     * @return {String|false} false or layout label if result of evaluation is true.
     */

  }, {
    key: 'isInLayout',
    value: function isInLayout(layout) {
      var self = this;
      if (typeof layout === 'string' || layout instanceof String) {
        layout = self.layouts[layout];
      }
      var layoutWidth = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
      var max = parseInt(layout.max, 10) || 99999;
      if (layoutWidth <= max) {
        return layout.label;
      }

      return false;
    }

    // =========================================================================
    // PRIVATE METHODS
    // =========================================================================

    /**
     * Initialize DOM elements with changing texts based on page layout.
     * @private
     */

  }, {
    key: '_initLayoutTexts',
    value: function _initLayoutTexts() {
      var self = this;
      this.ui.$layoutTextItems = $('[data-layout-text]');
      self.ui.$layoutTextItems.each(function (i, item) {
        LayoutManager.initInitialLayoutText($(item));
      });
    }

    /**
     * Determine actual layout and trigger an event if changes.
     * Event: 'layoutChange'
     * @private
     */

  }, {
    key: '_checkLayout',
    value: function _checkLayout() {
      this._setLayout();
      if (this.memory['actualLayout'] !== this.actualLayout) {
        console.log('Page Layout:', this.actualLayout);
        $(document).triggerHandler('layoutChange', { old: this.memory['actualLayout'], actual: this.actualLayout });
      }
    }

    /**
     * Determine actual orientation and trigger an event if changes.
     * Event: 'oriChange'
     * @private
     */

  }, {
    key: '_checkOrientation',
    value: function _checkOrientation() {
      this._setOrientation();
      if (this.memory['actualOrientation'] !== this.actualOrientation) {
        console.log('Orientation:', this.actualOrientation);
        $(document).triggerHandler('oriChange');
      }
    }

    /**
     * Determines the page structure based on viewport size and returns if it's
     * changes.
     * @private
     */

  }, {
    key: '_setLayout',
    value: function _setLayout() {
      /**
       * Store previous values
       */
      this.memory['actualLayout'] = this.actualLayout;

      /**
       * Determine new values
       */
      this.actualLayout = this._calculateActualLayout();
    }

    /**
     * Determines the actual orientation for the layout and stores previous
     * values.
     * @private
     */

  }, {
    key: '_setOrientation',
    value: function _setOrientation() {
      /**
       * Store previous value
       */
      this.memory['actualOrientation'] = this.actualOrientation;

      /**
       * Determine new value
       */
      if (window.innerHeight > window.innerWidth) {
        this.actualOrientation = 'P';
      } else {
        this.actualOrientation = 'L';
      }
    }

    /**
     * Change texts of DOM elements based on page layout.
     * @private
     */

  }, {
    key: '_setLayoutTexts',
    value: function _setLayoutTexts() {
      var self = this;
      this.ui.$layoutTextItems.each(function (i, item) {
        var $item = $(item);
        var layoutTextData = $item.data('layoutText');
        var thisLayoutText = false;
        if (typeof layoutTextData[self.actualLayout] !== 'undefined') {
          thisLayoutText = layoutTextData[self.actualLayout];
        }
        var newText = $item.data('initialLayoutText');
        if (thisLayoutText !== false) {
          newText = thisLayoutText;
        }
        $item.text(newText);
      });
    }

    /**
     * Determines site's actual layout.
     * @private
     *
     * @return {String|false} false or layout label.
     */

  }, {
    key: '_calculateActualLayout',
    value: function _calculateActualLayout() {
      var self = this;
      var layout = false;
      $.each(self.layouts, function (index, lay) {
        layout = LayoutManager.isLayout(lay);
        if (layout) {
          return false;
        }
      });
      return layout;
    }

    // =========================================================================
    // STATIC METHODS
    // =========================================================================

    /**
     * Initialize Responsive text element's initial text.
     * @static
     *
     * @param {jQuery} $item Item to initialize its layout text
     */

  }], [{
    key: 'initInitialLayoutText',
    value: function initInitialLayoutText($item) {
      var initialLayoutText = $item.text();
      $item.data('initialLayoutText', initialLayoutText);
    }

    /**
     * Checks if site is shown in a concrete layout.
     * @static
     *
     * @param {Object} layout Layout information
     * @return {String|false} false or layout label if result of evaluation is true.
     */

  }, {
    key: 'isLayout',
    value: function isLayout(layout) {
      var layoutWidth = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
      var min = parseInt(layout.min, 10) || 0;
      var max = parseInt(layout.max, 10) || 99999;
      if (layoutWidth <= max && layoutWidth >= min) {
        return layout.label;
      }

      return false;
    }
  }]);

  return LayoutManager;
}();