'use strict';

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _get = function get(object, property, receiver) { if (object === null) object = Function.prototype; var desc = Object.getOwnPropertyDescriptor(object, property); if (desc === undefined) { var parent = Object.getPrototypeOf(object); if (parent === null) { return undefined; } else { return get(parent, property, receiver); } } else if ("value" in desc) { return desc.value; } else { var getter = desc.get; if (getter === undefined) { return undefined; } return getter.call(receiver); } };

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

/**
 * Directus collection's item Base Model
 *
 * @author Brais Saco Estévez
 * @abstract
 * @extends Base
 * @lint global BaseItem
 */
window.BaseItem = function (_Base) {
  _inherits(BaseItem, _Base);

  function BaseItem() {
    _classCallCheck(this, BaseItem);

    return _possibleConstructorReturn(this, (BaseItem.__proto__ || Object.getPrototypeOf(BaseItem)).apply(this, arguments));
  }

  _createClass(BaseItem, [{
    key: '_initAttributes',


    // =========================================================================
    // INITIALIZATION
    // =========================================================================

    value: function _initAttributes() {
      var _get2;

      for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
        args[_key] = arguments[_key];
      }

      (_get2 = _get(BaseItem.prototype.__proto__ || Object.getPrototypeOf(BaseItem.prototype), '_initAttributes', this)).call.apply(_get2, [this].concat(args));

      /**
       * Field for manage collection's item status.
       * @type {string}
       */
      this.statusField = 'status';

      /**
       * Field value for item's published status.
       * @type {string}
       */
      this.statusPublished = 'published';

      /**
       * Field for manage collection's item translatable values.
       * @type {string}
       */
      this.translations = false; // new TranslationField();

      /**
       * Fields to manage for every collection item. Obvious other fields.
       * @type {Object}
       */
      this.fieldMap = {};

      /**
       * Defines the fields that are relation with another Directus' collections and its models.
       * @type {Object}
       */
      this.relations = {};
    }

    // =========================================================================
    // PUBLIC METHODS
    // =========================================================================

    /**
     * Retrieve Field map data.
     * @return {Object} Complete field map.
     */

  }, {
    key: 'getFieldMap',
    value: function getFieldMap() {
      return this.fieldMap;
    }

    /**
     * Check if the item has publication status.
     * @return {boolean} Checked property value
     */

  }, {
    key: 'hasStatus',
    value: function hasStatus() {
      return this.statusField && this.statusPublished;
    }

    /**
     * Retrieve translations manager list
     * @return {Object} Translations Manager.
     */

  }, {
    key: 'getTranslations',
    value: function getTranslations() {
      return this.translations;
    }

    /**
     * Check if the item has translatable values.
     * @return {boolean} Checked property value
     */

  }, {
    key: 'hasTranslations',
    value: function hasTranslations() {
      return !!this.translations;
    }

    /**
     * Retrieve a list of translatable fields for API query.
     * @return {[]} List of translatable fields for API query
     */

  }, {
    key: 'getQueryTranslatableFields',
    value: function getQueryTranslatableFields() {
      if (this.hasTranslations()) {
        return this.translations.getQueryTranslatableFields();
      }return [];
    }

    /**
     * Retrieve complete translation lang field.
     * @return {string} Lang field in translated item.
     */

  }, {
    key: 'getQueryTranslationLangField',
    value: function getQueryTranslationLangField() {
      if (this.hasTranslations()) {
        return this.translations.getQueryTranslationLangField();
      }return '';
    }

    /**
     * Retrieve relations list
     * @return {Object} Relations list.
     */

  }, {
    key: 'getRelations',
    value: function getRelations() {
      return this.relations;
    }

    /**
     * Check if this model has a relation in the field: 'filedName'.
     * @param {string} fieldName The field to search for a relation.
     * @return {boolean} The relation exists or not.
     */

  }, {
    key: 'isRelationField',
    value: function isRelationField(fieldName) {
      return this.relations.hasOwnProperty(fieldName);
    }

    /**
     * Retrieve relation's collection manager (Collection Class) from a field.
     * @param {string} fieldName The field to search for a relation.
     * @return {Object|false} Relation Collection Class or false.
     */

  }, {
    key: 'getRelationCollection',
    value: function getRelationCollection(fieldName) {
      var relationCollection = false;
      if (this.isRelationField(fieldName)) {
        relationCollection = this.relations[fieldName];
      }
      return relationCollection;
    }

    /**
     * Retrieve a list of translatable fields for API query.
     * @return {[]} List of translatable fields for API query
     */

  }, {
    key: 'getQueryRelationsFields',
    value: function getQueryRelationsFields() {
      var relationQueryFields = [];
      if (_.isEmpty(this.relations)) return relationQueryFields;

      _.each(this.relations, function (relationManager, relationField) {
        var fields = relationManager.getQueryFields();
        var linkField = relationManager.getLinkField();
        var relationFields = _.map(fields, function (field) {
          var toRet = relationField + '.' + (linkField ? linkField + '.' : '');
          return toRet + field;
        });
        relationQueryFields = relationQueryFields.concat(relationFields);
      });

      return relationQueryFields;
    }
  }]);

  return BaseItem;
}(Base);