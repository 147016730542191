/**
 * Directus Collection Model: 'Web' => Web generic information
 *
 * @author Brais Saco Estévez
 * @extends BaseCollection
 * @lint global WebCollection
 */
window.WebCollection = class WebCollection extends BaseCollection {


  // =========================================================================
  // INITIALIZATION
  // =========================================================================

  /**
   * @override
   * @see BaseCollection
   */
  _initAttributes(...args) {

    super._initAttributes(...args);

    this.collectionName = 'web';

    this.queryFields = ['*'];

    this.MODEL = WebItem;

  }

};
