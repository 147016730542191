'use strict';

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

/**
 * Base piece Management
 * @requires jQuery
 *
 * @lint global BasePieceController
 */
window.BasePieceController = function () {

  /**
   * Class constructor.
   * @param {string} prefix Prefix for the selector
   * @param {string} name DOM element identifier.
   */
  function BasePieceController() {
    var prefix = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '.piece-';
    var name = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : '';

    _classCallCheck(this, BasePieceController);

    this.selectorPrefix = prefix;

    this.name = name;

    this.dom = null;

    this.ui = {};

    this.binder = new Binder();

    this.init();
  }

  /**
   * Initialize class.
   */


  _createClass(BasePieceController, [{
    key: 'init',
    value: function init() {

      this.initUi();

      this.initEvents();

      this.initBehaviour();
    }

    /**
     * Initialize DOM reference.
     */

  }, {
    key: 'initUi',
    value: function initUi() {

      this.dom = $(this.selectorPrefix + this.name);
    }

    /**
     * Initialize DOM event handlers.
     * @abstract
     */

  }, {
    key: 'initEvents',
    value: function initEvents() {}

    // abstract class.

    /**
     * Initialize piece of DOM dynamic behaviours.
     * @abstract
     */

  }, {
    key: 'initBehaviour',
    value: function initBehaviour() {}

    // abstract class.

    /**
     * Check if this element exists in DOM
     * @return {number} 0: false, > 0: exists.
     */

  }, {
    key: 'exists',
    value: function exists() {

      return this.dom.length;
    }

    /**
     * Return if this elements is unique in DOM
     * @return {boolean} Is unique in DOM or not
     */

  }, {
    key: 'isUnique',
    value: function isUnique() {

      return this.exists() === 1;
    }

    /**
     * Initialize data binding for this element.
     * @param {object} data Data to bind in DOM
     */

  }, {
    key: 'bindData',
    value: function bindData(data) {

      this.binder.apply(data, this.dom);

      this.initBehaviour();
    }
  }]);

  return BasePieceController;
}();