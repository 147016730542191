'use strict';

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

/**
 * Class for Language selector block management
 * @extends BaseBlockController
 *
 * @lint global LangSelectorBlockController
 */
window.LangSelectorBlockController = function (_BaseBlockController) {
  _inherits(LangSelectorBlockController, _BaseBlockController);

  /**
   * Class constructor.
   */
  function LangSelectorBlockController() {
    _classCallCheck(this, LangSelectorBlockController);

    var _this = _possibleConstructorReturn(this, (LangSelectorBlockController.__proto__ || Object.getPrototypeOf(LangSelectorBlockController)).call(this, 'lang-selector'));

    _this.selectedLangClass = 'selected-lang';

    return _this;
  }

  /**
   * @see parent.initBehaviour
   */


  _createClass(LangSelectorBlockController, [{
    key: 'initBehaviour',
    value: function initBehaviour() {

      this.initActualLanguage();
      this.initClickable();
    }

    /**
     * Initialize data binding for this element.
     * @param {object} data Data to bind in DOM
     * @param {boolean} scoped Scope binding to this DOM element.
     */

  }, {
    key: 'bindData',
    value: function bindData(data) {
      var scoped = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : true;


      var formattedData = [];
      data.forEach(function (languageData) {
        if (languageData.activo) formattedData.push(new LanguageModel(languageData));
      });

      this.binder.apply(formattedData, scoped ? this.dom : undefined);

      this.initBehaviour();
    }

    /**
     * Initialize selected language DOM item
     */

  }, {
    key: 'initActualLanguage',
    value: function initActualLanguage() {

      var actualLang = window.siteConfig.lang.default; // Default lang.
      if (window.siteManager) {
        actualLang = window.siteManager.lang;
      }
      this.dom.find('.nav-link').filter('[data-lang=\'' + actualLang + '\']').parent().addClass(this.selectedLangClass);
      this.dom.find('.nav-link').not('[data-lang=\'' + actualLang + '\']').parent().removeClass(this.selectedLangClass);
    }

    /**
     * Initialize click event on every language selector item.
     */

  }, {
    key: 'initClickable',
    value: function initClickable() {

      this.dom.find('[data-lang]').off('click').on('click', function (event) {
        event.preventDefault();
        var $item = $(event.currentTarget);
        var lang = $item.data('lang');
        window.siteManager.changeLanguage(lang);
      });
    }
  }]);

  return LangSelectorBlockController;
}(BaseBlockController);