'use strict';

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _get = function get(object, property, receiver) { if (object === null) object = Function.prototype; var desc = Object.getOwnPropertyDescriptor(object, property); if (desc === undefined) { var parent = Object.getPrototypeOf(object); if (parent === null) { return undefined; } else { return get(parent, property, receiver); } } else if ("value" in desc) { return desc.value; } else { var getter = desc.get; if (getter === undefined) { return undefined; } return getter.call(receiver); } };

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

/**
 * Directus Field Model: 'File and Image file'
 * For all files and image of a collection
 *
 * @author Brais Saco Estévez
 * @extends Base
 * @lint global FileField
 */
window.FileField = function (_BaseField) {
  _inherits(FileField, _BaseField);

  function FileField() {
    _classCallCheck(this, FileField);

    return _possibleConstructorReturn(this, (FileField.__proto__ || Object.getPrototypeOf(FileField)).apply(this, arguments));
  }

  _createClass(FileField, [{
    key: '_initAttributes',


    // =========================================================================
    // INITIALIZATION
    // =========================================================================

    /**
     * @override
     * @see Base
     */
    value: function _initAttributes() {
      var _get2;

      for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
        args[_key] = arguments[_key];
      }

      (_get2 = _get(FileField.prototype.__proto__ || Object.getPrototypeOf(FileField.prototype), '_initAttributes', this)).call.apply(_get2, [this].concat(args));

      /**
       * @override
       */
      this.queryFields = ['*'];

      /**
       * @override
       */
      this.fieldMap = {
        type: 'type',
        title: 'title',
        filesize: 'filesize',
        width: 'width',
        height: 'height',
        description: 'description',
        location: 'location',
        tags: 'tags',
        private_hash: 'private_hash',
        'data.full_url': 'full_url',
        'data.thumbnails': 'thumbnails',
        metadata: 'metadata'
      };
    }

    // =========================================================================
    // PUBLIC METHODS
    // =========================================================================

    /**
     * Retrieve thumbnail data filtering by key in URLs as strings.
     * @param {Object} imageData Image Data values
     * @param {string} key Thumbnail identifier: 'small-crop', 'thumbnail', ...
     * @return {Object} Thumbnail complete info.
     */

  }], [{
    key: 'getThumbnailDataByKey',
    value: function getThumbnailDataByKey(imageData, key) {
      return _.filter(imageData.thumbnails, function (thumb) {
        return thumb.url.indexOf(key) > -1;
      }).pop();
    }

    /**
     * Retrieve asset from an image using Directus asset's manager. (Uses Directus assets whitelist)
     * @see https://docs.directus.io/guides/files.html#asset-middleware
     * @param {Object} imageData Image Data values from directus
     * @param {string} key Thumbnail identifier: 'small-crop', 'thumbnail', .
     * @return {string} Asset URL
     */

  }, {
    key: 'getDirectusAsset',
    value: function getDirectusAsset(imageData, key) {
      return window.dataApi.url + window.dataApi.project + '/assets/' + imageData.private_hash + '?key=' + key;
    }

    /**
     * Retrieve absolute URL on Directus API
     * @param {Object} fileData File Data Values from directus.
     * @return {string} File absolute URL
     */

  }, {
    key: 'getFileFullUrl',
    value: function getFileFullUrl(fileData) {
      return fileData.data.full_url;
    }
  }]);

  return FileField;
}(BaseField);