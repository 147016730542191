'use strict';

/**
 * Extends Jquery Behaviours
 */

(function ($) {

  // Get Outer HTML for a selector.
  $.fn.outerHtml = function () {
    return this[0].outerHTML;
  };

  // Attribute Begins with selector.
  $.extend($.expr[':'], {
    attrStartsWith: function attrStartsWith(el, _, b) {
      for (var i = 0, atts = el.attributes, n = atts.length; i < n; i++) {
        if (atts[i].nodeName.toLowerCase().indexOf(b[3].toLowerCase()) === 0) {
          return true;
        }
      }
      return false;
    }
  });

  $.extend({

    // Replace element tag for another.
    replaceTag: function replaceTag(element, tagName, withDataAndEvents, deepWithDataAndEvents) {
      var newTag = $('<' + tagName + '>')[0];
      // From [Stackoverflow: Copy all Attributes](http://stackoverflow.com/a/6753486/2096729)
      $.each(element.attributes, function () {
        newTag.setAttribute(undefined.name, undefined.value);
      });
      $(element).children().clone(withDataAndEvents, deepWithDataAndEvents).appendTo(newTag);
      return newTag;
    },

    /**
     * Scroll window to target
     * @param {Object} options Scrolling options.
     */
    scrollTo: function scrollTo(options) {
      var target = options;
      var scrollTop = target;
      var defaults = {
        target: '',
        speed: 'slow',
        easing: 'swing',
        onAfter: $.noop
      };
      var settings = {};

      if ($.isPlainObject(options)) {
        settings = $.extend({}, defaults, options);
        target = settings.target;
        scrollTop = target;
      } else {
        settings = defaults;
      }

      if (target instanceof jQuery) {
        scrollTop = target.offset().top;
      } else if (typeof target === 'string' || target instanceof String) {
        var $target = $(target);
        if ($target.length) scrollTop = $(target).offset().top;
      }

      var actualScroll = $(window).scrollTop();
      if (actualScroll === scrollTop) {
        settings.onAfter(false);
        return;
      }

      $('html,body').animate({
        scrollTop: scrollTop
      }, settings.speed, settings.easing).promise().done(function () {
        settings.onAfter(true);
      });
    },


    // Equalizes all element's height to higher one.
    maximize: function maximize(elements) {
      $(elements).css('height', '');
      var maxHeight = 0;
      $(elements).each(function (idx, item) {
        var $item = $(item);
        var itemHeight = parseInt($item.height(), 10);
        if (itemHeight > maxHeight) {
          maxHeight = itemHeight;
        }
      });
      $(elements).height(maxHeight);
    }
  });

  $.fn.extend({
    replaceTag: function replaceTag(tagName, withDataAndEvents, deepWithDataAndEvents) {
      return (
        // Use map to reconstruct the selector with newly created elements
        undefined.map(function () {
          return jQuery.replaceTag(undefined, tagName, withDataAndEvents, deepWithDataAndEvents);
        })
      );
    }

  });

  /**
   * Trigger a callback when scroll action ends.
   * @param {function} callback Callback to call when scroll action ends.
   * @param {number} timeout Time to check when scroll action ends.
   */
  $.fn.scrollEnd = function (callback) {
    var timeout = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 200;

    $(this).scroll(function () {
      var $this = $(this);
      if ($this.data('scrollTimeout')) {
        clearTimeout($this.data('scrollTimeout'));
      }
      $this.data('scrollTimeout', setTimeout(callback, timeout));
    });
  };

  /**
   * Checks if an element is visible in viewport
   * @param {number} offset Offset for viewport
   * @return {boolean} Is visible or not
   */
  $.fn.isInViewport = function () {
    var offset = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 0;

    var elementTop = $(this).offset().top;
    var elementBottom = elementTop + $(this).outerHeight();

    var viewportTop = $(window).scrollTop();
    var viewportBottom = viewportTop + $(window).height();

    return elementBottom >= viewportTop + offset && elementTop <= viewportBottom - offset;
  };
})(jQuery);